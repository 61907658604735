require('formdata-polyfill'); // import FormData into global. FormData does not work in safari worker
require('abortcontroller-polyfill/dist/abortcontroller-polyfill-only');

import {
  FEED_WIDGET_ID_PROD,
  POST_WIDGET_ID_PROD,
  POST_LIST_BOB_WIDGET_TYPE,
  POST_TICKER_WIDGET_TYPE,
  CATEGORY_LIST_WIDGET_TYPE,
  ARCHIVE_LIST_WIDGET_TYPE,
  CATEGORY_MENU_WIDGET_ID,
  ARCHIVE_WIDGET_ID,
  POST_LIST_WIDGET_ID,
} from './constants/widgets';
import { createNoopController } from './controllers/createNoopController';
import { createFeedPageController } from '../feed-page/controller';
import { createPostPageController } from '../post-page/controller';
import { createPostListBobController } from '../post-list-widget-bob/controller';
import { createPostListController } from '../post-list-widget/controller';
import { createPostTickerController } from '../post-ticker-widget/controller';
import { createCategoryListController } from '../category-list-widget/controller';
import { createArchiveListController } from '../archive-list-widget/controller';
import { createCategoryMenuController } from '../category-menu-widget/controller';
import { createArchiveController } from '../archive-widget/controller';

const CONTROLLER_FACTORY_MAP = {
  [FEED_WIDGET_ID_PROD]: createFeedPageController,
  [POST_WIDGET_ID_PROD]: createPostPageController,
  [POST_LIST_BOB_WIDGET_TYPE]: createPostListBobController,
  [POST_TICKER_WIDGET_TYPE]: createPostTickerController,
  [CATEGORY_LIST_WIDGET_TYPE]: createCategoryListController,
  [ARCHIVE_LIST_WIDGET_TYPE]: createArchiveListController,
  [CATEGORY_MENU_WIDGET_ID]: createCategoryMenuController,
  [ARCHIVE_WIDGET_ID]: createArchiveController,
  [POST_LIST_WIDGET_ID]: createPostListController,
};

export const initAppForPage = (data, api, wixCodeApi) => {
  if (process.env.NODE_ENV !== 'production' || wixCodeApi.location.query.debug) {
    console.log('initAppForPage', { data, api, wixCodeApi });
  }
  return Promise.resolve();
};

export const createControllers = controllerConfigs => {
  return controllerConfigs.map(controllerConfig => {
    const factory = CONTROLLER_FACTORY_MAP[controllerConfig.type] || createNoopController;
    return factory(controllerConfig, controllerConfigs);
  });
};

export const exports = {};
