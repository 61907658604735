import { get } from 'lodash';
import { EXPERIMENT_FETCH_POST_METADATA_SEPARATELY } from '@wix/communities-blog-experiments';
import { isExperimentEnabled } from '@wix/communities-blog-client-common/dist/src/selectors/experiments-selectors';
import { Router } from '../../common/router';
import {
  ROUTE_404,
  ROUTE_CREATE_POST,
  ROUTE_EDIT_POST,
  ROUTE_PREVIEW_POST,
  ROUTE_EDIT_COMMENT,
  ROUTE_POST,
  ROUTE_POST_ADVANCED_SLUG,
  ROUTE_LAST_POST,
  ROUTE_EDIT_COMMENT_ADVANCED_SLUG,
  ROUTE_ACCOUNT_SUSPENDED,
  ROUTE_LOGIN,
} from '../constants/routes';
import { fetchPost } from '../../common/actions/fetch-post';
import { fetchLastPost } from '../actions/fetch-last-post';
import fetchRecentPosts from '../actions/fetch-recent-posts';
import { resolvePostSlug } from '../../common/services/slug';
import { pageOpened } from '../../common/actions/page-opened';
import { POST_PAGE, POST_EDIT_PAGE, POST_PAGE_NOT_FOUND } from '../../common/services/detect-route';
import { setIsPostInPreview } from '../actions/set-is-post-in-preview';
import {
  getCurrentUser,
  isBlocked,
} from '@wix/communities-blog-client-common/dist/src/selectors/current-user-selectors';
import { POST_STATUS } from '@wix/communities-blog-universal/dist/src/constants/post';
import { createPermissionsChecker } from '../../common/services/create-permission-helpers';
import {
  getIsMobile,
  isSeo,
  isSSR,
  getBasicParams,
  isEditor,
  isSite,
  isPreview,
} from '../../common/store/basic-params/basic-params-selectors';
import { getIsCommentsEnabled, getIsRecentPostsEnabled } from '../../common/selectors/app-settings-selectors';
import createPermissionChecker from '../../common/services/create-permission-checker';
import { COMMENTS_PER_PAGE } from '@wix/communities-blog-client-common/dist/src/constants/pagination';
import { fetchComments } from '../../common/store/comments/fetch-comments';
import { clearComments } from '../../common/store/comments/clear-comments';
import { fetchInitialPostEditorData } from '../services/post-editor';
import { generatePostPageSEOTags } from '../../common/services/generate-seo-tags';
import { getAppConfig } from '../../common/store/app-config/app-config-selectors';
import { getIsDemoMode } from '../../common/store/instance-values/instance-values-selectors';
import { incrementPostViewCount } from '../../common/actions/increment-post-view-count';
import { createNotFoundPageRouter } from '../../common/controller/create-router';
import { fetchPostMetadata } from '../../common/actions/fetch-post-metadata';

const assertUserLoggedInAndNotBlocked = (state, redirect) => {
  const currentUser = getCurrentUser(state);
  if (currentUser) {
    if (isBlocked(state)) {
      return { isUserValid: false, redirectState: redirect(ROUTE_ACCOUNT_SUSPENDED) };
    }
  } else {
    return { isUserValid: false, redirectState: redirect(ROUTE_LOGIN) };
  }
  return { isUserValid: true };
};

const fetchRecentPostsAndComments = async (store, post) => {
  const state = store.getState();
  const isClient = !isSSR(state);
  const isMobile = getIsMobile(state);

  isExperimentEnabled(state, EXPERIMENT_FETCH_POST_METADATA_SEPARATELY) &&
    isClient &&
    store.dispatch(fetchPostMetadata(post._id));

  if (getIsRecentPostsEnabled(state)) {
    if (isSeo(state)) {
      await store.dispatch(fetchRecentPosts(post));
    } else if (isClient) {
      store.dispatch(fetchRecentPosts(post));
    }
  }

  const showComments = isClient && getIsCommentsEnabled(state);
  if (showComments) {
    store.dispatch(clearComments());
    store.dispatch(
      fetchComments({
        postId: post._id,
        pageSize: COMMENTS_PER_PAGE,
        page: parseInt(getBasicParams(state).page || 1, 10),
      }),
    );
  }

  !isSSR(state) && store.dispatch(pageOpened({ page: POST_PAGE, post, isMobile, isEditor: isEditor(state) }));
};

const createPostPageRouter = (store, wixCodeApi, appConfig) => async ({ params }, redirect) => {
  const postSlug = resolvePostSlug(params);
  return store
    .dispatch(fetchPost(postSlug))
    .then(post => {
      if (post.status !== POST_STATUS.published) {
        redirect(`/${post._id}/edit`);
      }

      const state = store.getState();

      if (!getIsDemoMode(state) && !isSSR(state)) {
        store.dispatch(incrementPostViewCount(post._id));
      }

      if (isSite(state)) {
        const postPageSEOTags = generatePostPageSEOTags({
          appConfig,
          post,
          state,
        });
        wixCodeApi.seo.renderSEOTags(postPageSEOTags);
      }
      return fetchRecentPostsAndComments(store, post);
    })
    .catch(error => {
      if (error.status === 401) {
        return redirect(`/login?redirect=/${postSlug}`);
      }
      if (error.status === 404) {
        return redirect(ROUTE_404);
      }
      throw error;
    });
};

const createPostPreviewPageRouter = store => async ({ params }, redirect) => {
  const canPreviewPost = (state, post) => {
    if (!get(state, 'auth.isAuthenticated')) {
      return false;
    }
    const user = getCurrentUser(state);
    const perm = createPermissionChecker(state, user);
    const can = createPermissionsChecker(perm);
    return can('preview', 'post', post);
  };

  const postSlug = resolvePostSlug(params);

  return store
    .dispatch(fetchPost(postSlug))
    .then(post =>
      canPreviewPost(store.getState(), post) ? store.dispatch(setIsPostInPreview(true)) : redirect(ROUTE_404),
    )
    .catch(error => {
      if (error.status === 401) {
        return redirect(`/login?redirect=/${postSlug}`);
      }
      if (error.status === 404) {
        return redirect(ROUTE_404);
      }
      throw error;
    });
};

const createPostEditPageRouter = store => async ({ params }, redirect) => {
  const state = store.getState();

  const { isUserValid, redirectState } = assertUserLoggedInAndNotBlocked(state, redirect);
  if (!isUserValid) {
    return redirectState;
  }

  const postSlug = resolvePostSlug(params);
  return fetchInitialPostEditorData(state, store.dispatch, postSlug).then(
    () => !isSSR(state) && store.dispatch(pageOpened({ page: POST_EDIT_PAGE })),
  );
};

const createPostCreatePageRouter = store => async (_route, redirect) => {
  const state = store.getState();

  const { isUserValid, redirectState } = assertUserLoggedInAndNotBlocked(state, redirect);
  if (!isUserValid) {
    return redirectState;
  }

  return fetchInitialPostEditorData(state, store.dispatch).then(
    () => !isSSR(state) && store.dispatch(pageOpened({ page: POST_EDIT_PAGE })),
  );
};

const createLastPostPageRouter = store => async (_route, redirect) => {
  const state = store.getState();
  if (!(isEditor(state) || isPreview(state))) {
    return redirect(ROUTE_404);
  }

  const lastPost = await store.dispatch(fetchLastPost());
  if (lastPost) {
    await fetchRecentPostsAndComments(store, lastPost);
  }
};

export const createRouter = (store, _config, wixCodeApi) => {
  const appConfig = getAppConfig(store.getState());
  const router = new Router();
  router.add(ROUTE_LAST_POST, createLastPostPageRouter(store));
  router.add(ROUTE_404, createNotFoundPageRouter(store, wixCodeApi, POST_PAGE_NOT_FOUND, ROUTE_404));
  router.add(ROUTE_LOGIN);
  router.add(ROUTE_CREATE_POST, createPostCreatePageRouter(store));
  router.add(ROUTE_EDIT_POST, createPostEditPageRouter(store));
  router.add(ROUTE_PREVIEW_POST, createPostPreviewPageRouter(store));
  router.add(ROUTE_EDIT_COMMENT);
  router.add(ROUTE_POST, createPostPageRouter(store, wixCodeApi, appConfig));
  router.add(ROUTE_POST_ADVANCED_SLUG, createPostPageRouter(store, wixCodeApi, appConfig));
  router.add(ROUTE_EDIT_COMMENT_ADVANCED_SLUG);
  router.fallback(ROUTE_404);
  return router;
};
